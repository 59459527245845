var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company-information" },
    [
      _c("div", { staticClass: "info-main" }, [
        _c("div", { staticClass: "main-title" }, [
          _vm._v(_vm._s(_vm.baseInfoList.tenantName) + " "),
        ]),
      ]),
      _c(
        "a-descriptions",
        { attrs: { column: 1, colon: false } },
        [
          _c("a-descriptions-item", { attrs: { label: "所属行业" } }, [
            _vm._v(" " + _vm._s(_vm.baseInfoList.businessName) + " "),
          ]),
          _c("a-descriptions-item", { attrs: { label: "企业规模" } }, [
            _vm._v(" " + _vm._s(_vm.scaleName) + " "),
          ]),
          _c("a-descriptions-item", { attrs: { label: "注册资本" } }, [
            _vm._v(" " + _vm._s(_vm.baseInfoList.registerCapital) + " 万元 "),
          ]),
          _c("a-descriptions-item", { attrs: { label: "注册地" } }, [
            _vm._v(" " + _vm._s(_vm.baseInfoList.areaName) + " "),
          ]),
          _c("a-descriptions-item", { attrs: { label: "统一社会信用代码" } }, [
            _vm._v(" " + _vm._s(_vm.baseInfoList.uniFormCode) + " "),
          ]),
          _c("a-descriptions-item", { attrs: { label: "法人代表" } }, [
            _vm._v(" " + _vm._s(_vm.baseInfoList.corporate) + " "),
          ]),
          _c("a-descriptions-item", { attrs: { label: "联系方式" } }, [
            _vm._v(" " + _vm._s(_vm.baseInfoList.corporateContact) + " "),
          ]),
          _c("a-descriptions-item", { attrs: { label: "邮政编码" } }, [
            _vm._v(" " + _vm._s(_vm.baseInfoList.postalCode) + " "),
          ]),
          _c("a-descriptions-item", { attrs: { label: "营业执照" } }, [
            _vm.baseInfoList.licenseFileId
              ? _c("img", { attrs: { src: _vm.imgSrc } })
              : _c("img", { attrs: { src: "" } }),
          ]),
          _c(
            "a-descriptions-item",
            { attrs: { label: "" } },
            [
              _c(
                "a-button",
                { attrs: { prefix: "bianji" }, on: { click: _vm.handleEdit } },
                [_vm._v("编辑")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("InductionClassForms", {
        ref: "baseInfoListForm",
        attrs: { "scale-list": _vm.scaleList },
        on: { getFetchDataApiFn: _vm.getFetchDataApiFn },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }