import { GLOBAL_SETTING } from '../api'
import { METHOD, request } from '@/utils/request'

// 获取企业信息
export async function getTenantInfo(params) {
  return request(`${GLOBAL_SETTING}/enterprise/getTenantInfo`, METHOD.GET, params)
}

// 更新企业信息
export async function updateTenant(params) {
  return request(`${GLOBAL_SETTING}/enterprise/updateTenant`, METHOD.POST, params)
}
